import { Application } from "@hotwired/stimulus"

const application = Application.start()

// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application
import BrandCarsController from "./brand_cars_controller"
application.register("brandCars", BrandCarsController)

import DropdownController from "./dropdown_controller"
application.register("dropdown", DropdownController)

import ModalController from "./modal_controller"
application.register("modal", ModalController)

import CarController from "./car_controller";
application.register('car', CarController)

import ComparisonModalController from "./comparison_modal_controller"
application.register("comparisonModal", ComparisonModalController)

import EditCarController from "./edit_car_controller"
application.register("editCar", EditCarController)

export { application }
